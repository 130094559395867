/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
    font-family: "Poppin";
    src: url(./assets/Poppins/Poppins-Medium.ttf);

}

* {
    font-family: "Poppin";

}

.ng-select.ng-select-single .ng-select-container {
    height: auto;
  }
  .ng-select-container .ng-value-container .ng-value {
    font-size: .9rem;
  }

.validattion-error {
    color: red;
}

.container {
    margin-left: 18%;
    width: 79%;
    margin-top: 100px;
}

a {
    cursor: pointer;
}

.confirm {
    width: 90%;
    background-color: #00A5AB;
    color: white;
}

.squarecircle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #00A5AB;
    color: white;
    /* margin-top: -100px; */
    margin: auto;
    display: block;
    justify-content: center;
}

.squarecircle img {
    width: 70%;
    height: auto;
    margin-top: 8px;
}

.outer {
    margin-top: -40px;
}

td {
    font-size: small;
}

@media screen and (max-width:960px) {
    .container {
        width: 100%;
        margin-left: 0;
        margin-top: 120px;
        margin-bottom: 100px;
    }
}